import AuthService from '../services/auth.service';
import UserService from '../services/user.service';

export const user = {
    namespaced: true,
    // state: {initialState},
    state: {
        rawA1: "",
        rawA2: "",
        rawA3: "",
        userId: "",
        province: "",
    },
    actions: {
        data({ commit }, data) {
            return UserService.addData(data).then(
                addedData => {
                    console.log('usermodule addeddata')
                    commit('dataAddedSuccess', addedData);
                    return Promise.resolve(addedData);
                },
                error => {
                    commit('dataAddedFailure')
                    return Promise.reject(error);
                }
            )
        },
        login({ commit }, userlocal) {
            return AuthService.login(userlocal).then(
                userlocal => {
                    console.log('login')
                    commit('loginSuccess', userlocal);
                    return Promise.resolve(userlocal);
                },
                error => {
                    commit('loginFailure');
                    return Promise.reject(error);
                }
            );
        },
        logout( { commit }) {
            AuthService.logout();
            commit('logout');
        },
    },
    mutations: {
        dataAddedSuccess(state, data) {
            console.log('dataadd success')
            console.log(data)
        },
        dataAddedFailure(state) {
            console.log(state)
            console.log('data added failure')
        },
        loginSuccess(state, user) {
            state.status.loggedIn = true;
            state.user = user;
        },
    }
};