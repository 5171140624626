<template>

  <div class="container">
    <header>
      <h3>{{ content }}</h3>
    </header>
    <geo-chart id='nochart' :library="library" :data="data" width="800px" height="600px"></geo-chart>

  </div>
</template>
<script setup>
  
</script>
<script>
import UserService from "../services/user.service";


export default {
  name: "Home",
  data() {
    return {
      content: "",
      library: {
        region: 'TH',
        resolution: 'provinces',
        colorAxis: { minValue : Math.min([1]), maxValue : Math.max([100,1]), colors : ['#E3D0B9','#62959C']},
        onClick: this.handle
      },
      data: this.data,
      counts: this.counts
    };
  },
  beforeCreate() {

  },
  mounted() {
    
    UserService.getPublicContent().then(
      (response) => {
        this.content = response.data.message;
        this.data = response.data.data;
        this.counts = response.data.counts;
        this.library.colorAxis.minValue = Math.min(...this.counts);
        this.library.colorAxis.maxValue = Math.max(...this.counts);
      },
      (error) => {
        this.content =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
      }
    );
  },
};
</script>