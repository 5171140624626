import axios from 'axios';
import authHeader from './auth-header';

const API_URL = 'https://api.maikinwaan.com/test/';

class UserService {
    getPublicContent() {
      return axios.get(API_URL + 'all');
    }
  
    getUserBoard() {
      return axios.get(API_URL + 'user', { headers: authHeader() });
    }
  
    getAdminBoard() {
      return axios.get(API_URL + 'admin', { headers: authHeader() });
    }
    async addData(data) {
      console.log('axio adddata')
      console.log(authHeader())
      console.log('-----')
      return axios.post(API_URL + 'data', data, { headers: authHeader()})
        .then(response => {
          console.log(`response: ${response}`)
          console.log(response)
          return response.data;
        });
    }
  }
  
  export default new UserService();