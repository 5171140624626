import { createApp } from 'vue'
import App from './App.vue'
import router from './router';
import store from './store';
import 'bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import { FontAwesomeIcon } from './plugins/font-awesome';
import VueChartkick from 'vue-chartkick';
import 'chartkick/chart.js'
VueChartkick.configure({language: "th", mapsApiKey: "AIzaSyC5qDzjaWhm6oy5hbctylWgJHVj4W7NNYE"})
createApp(App)
    .use(router)
    .use(store)
    .use(VueChartkick)
    .component('font-awesome-icon', FontAwesomeIcon)
    .mount('#app');
