export default function authHeader() {
    let user = JSON.parse(localStorage.getItem('user'));
    console.log('auth header');
    console.log(user)
    if (user && user.accessToken) {
        // return { Authorization: 'Bearer ' + user.accessToken };
        console.log('auth header ok')
        return { 'x-access-token': user.accessToken };
    } else {
        console.log('auth header fail')
        return {};
    }
}